.border-none[data-v-308ccd38]:before {
  display: none;
}
.border-none[data-v-308ccd38]:after {
  display: none;
}
.flex-fix[data-v-308ccd38] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-308ccd38]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-308ccd38] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-308ccd38] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-308ccd38] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-308ccd38] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-308ccd38] {
  *zoom: 1;
}
.clear-fix[data-v-308ccd38]:before,
.clear-fix[data-v-308ccd38]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.apply-sale[data-v-308ccd38] {
  padding-bottom: 1.5rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.apply-sale overflow-y:scroll .goods-content[data-v-308ccd38] {
  padding: 0.3rem 0.3rem 0.2rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.apply-sale .good-number-wrapper[data-v-308ccd38] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.2rem 0.3rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 0.28rem;
  color: #666666;
  height: 0.8rem;
}
.apply-sale .good-number-wrapper .left[data-v-308ccd38] {
  margin-right: 0.5rem;
}
.apply-sale .sale-title[data-v-308ccd38] {
  padding: 0.2rem 0.3rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 0.28rem;
  color: #666666;
  height: 0.8rem;
}
.apply-sale .reason-contnet[data-v-308ccd38] {
  background: #fff;
  padding-left: 0.3rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.apply-sale .reason-contnet .reason-item[data-v-308ccd38] {
  height: 0.88rem;
  border-bottom: 1px solid #e1e1e1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-right: 0.3rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 0.32rem;
  color: #333333;
}
.apply-sale .reason-contnet .reason-item .icon[data-v-308ccd38] {
  width: 0.28rem;
  height: 0.28rem;
}
.apply-sale .reason-contnet .reason-item .icon img[data-v-308ccd38] {
  width: 0.28rem;
  height: 0.28rem;
}
.apply-sale .sale-detail[data-v-308ccd38] {
  background: #fff;
  padding: 0.2rem 0.3rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 2rem;
}
.apply-sale .sale-detail textarea[data-v-308ccd38] {
  width: 100%;
  height: 1.34rem;
  border: none;
}
.apply-sale .sale-detail .inp-num[data-v-308ccd38] {
  font-size: 0.26rem;
  color: #cccccc;
  text-align: right;
}
.apply-sale .img-box[data-v-308ccd38] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.apply-sale .img-box .img-item[data-v-308ccd38] {
  width: 2.2rem;
  height: 2.2rem;
  margin-left: 0.2rem;
  background: #fff;
  position: relative;
}
.apply-sale .img-box .img-item .up-img[data-v-308ccd38] {
  width: 2.2rem;
  height: 2.2rem;
}
.apply-sale .img-box .img-item .icon[data-v-308ccd38] {
  width: 0.28rem;
  height: 0.28rem;
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 99;
}
.apply-sale .img-box .upload-box[data-v-308ccd38] {
  position: relative;
  width: 2.2rem;
  height: 2.2rem;
}
.apply-sale .img-box .upload-box input[data-v-308ccd38] {
  position: absolute;
  width: 2.2rem;
  height: 2.2rem;
  right: 0;
  top: 0;
  opacity: 0;
}
.apply-sale .img-box .upload-box .upload-bg[data-v-308ccd38] {
  width: 2.2rem;
  height: 2.2rem;
}
.apply-sale .img-box .up-item[data-v-308ccd38] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
}
.apply-sale .bottom[data-v-308ccd38] {
  width: 100%;
  background: #fff;
  height: 1.2rem;
  position: fixed;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-top: 1px solid #e1e1e1;
}
.apply-sale .bottom .bottom-btn[data-v-308ccd38] {
  width: 6.9rem;
  height: 0.88rem;
  border-radius: 0.88rem;
  background: var(--main-color);
  text-align: center;
  line-height: 0.88rem;
  font-size: 0.32rem;
  color: #fff;
}
